<template>
  <section class="py-10   px-4 lg:px-10   border-b-2 border-white">
    <div class="flex flex-col justify-center">
      <h1 class="mt-20   md:mx-20 text-xl font-bold">Impressum</h1>
      <div class="mt-20  md:mx-20">
        ZW Systems GmbH
        <br />
        Konrad-Zuse-Ring 41
        <br />
        53424 Remagen
        <br /><br />
        Geschäftsführung: Thomas Dolon, Stefan Wisskirchen
        <br /><br />
        <b>Registereintrag</b>
        <br />
        Eintragung im Handelsregister.
        <br />
        Registergericht: Amtsgericht KoblenzRegisternummer: HRB 27979
        <br />Umsatzsteuer<br />Umsatzsteuer-Identifikationsnummer gemäß § 27 a
        Umsatzsteuergesetz: DE341309392 <br /><br />
        <b>EU-Streitschlichtung</b>
        <br />
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit: https://ec. europa.
        eu/consumers/odr.
        <br />
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
        <br /><br />
        <b>Verbraucher­­streit­beilegung/Universal­­schlichtungs­stelle</b>
        <br />
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
        <br /><br />
        <b>Haftung für Inhalte</b>
        <br />
        Als Diensteanbieter sind wir gemäß § 7 Abs.
        <br />
        1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
        verantwortlich.
        <br />
        Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
        verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
        überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
        Tätigkeit hinweisen.
        <br />
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
        <br />
        Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
        Kenntnis einer konkreten Rechtsverletzung möglich.
        <br />
        Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
        Inhalte umgehend entfernen.
        <br /><br />
        <b>Haftung für Links</b>
        <br />
        Unser Angebot enthält Links zu externen Websites Dritter, auf deren
        Inhalte wir keinen Einfluss haben.
        <br />
        Deshalb können wir für diese fremden Inhalte auch keine Gewähr
        übernehmen.
        <br />
        Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
        oder Betreiber der Seiten verantwortlich.
        <br />
        Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
        Rechtsverstöße überprüft.
        <br />
        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
        erkennbar.
        <br />
        Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
        ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
        <br />
        Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
        umgehend entfernen.
        <br /><br />
        <b>Urheberrecht</b>
        <br />
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht.
        <br />
        Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
        schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        <br />
        Downloads und Kopien dieser Seite sind nur für den privaten, nicht
        kommerziellen Gebrauch gestattet.
        <br />
        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
        werden die Urheberrechte Dritter beachtet.
        <br />
        Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
        <br />
        Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
        bitten wir um einen entsprechenden Hinweis.
        <br />
        Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
        umgehend entfernen.
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Imprint",
  components: {},
};
</script>
